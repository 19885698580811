import $ from "jquery";
$(document).ready(function() {
    $('#contactForm').on('submit', function(e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: '/mail/send',
            data: $(this).serialize(),
            success: function(data) {
                $('#formResult').html('<p>Form submitted successfully!</p>');
                $('#contactForm').trigger('reset');
            },
            error: function() {
                $('#formResult').html('<p>An error occurred.</p>');
            }
        });
    });
});